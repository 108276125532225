import React from "react";

const Pagination = ({ totalItems, totalPages, currentPage, onPageChange }) => {
    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex flex-row-reverse justify-center items-center mt-5">
            <button
                onClick={() => handlePageClick(currentPage - 1)}
                className="px-4 py-2 bg-[#1f5f79] text-white rounded-l-md"
                disabled={currentPage === 1}
            >
                قبلی
            </button>
            {pageNumbers.map((page) => (
                <button
                    key={page}
                    onClick={() => handlePageClick(page)}
                    className={`px-4 py-2 mx-1 rounded-md ${
                        page === currentPage
                            ? "bg-[#247392] text-white"
                            : "bg-[#252525] text-white hover:bg-[#678fa062]"
                    }`}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={() => handlePageClick(currentPage + 1)}
                className="px-4 py-2 bg-[#1f5f79] text-white rounded-r-md"
                disabled={currentPage === totalPages}
            >
                بعدی
            </button>
        </div>
    );
};

export default Pagination;
