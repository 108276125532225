import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const Filters = ({ statusOptions, onFilterChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("");

  const handleApplyFilters = () => {
    const formattedStartDate = startDate
      ? startDate.toDate().toISOString().split("T")[0] // فقط تاریخ
      : null;
    const formattedEndDate = endDate
      ? endDate.toDate().toISOString().split("T")[0] // فقط تاریخ
      : null;

    onFilterChange({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      status,
    });
  };

  return (
    <div className="flex items-center gap-4 p-4 bg-gray-800 rounded-lg">
      <div>
        <label className="text-white block mb-2">از تاریخ:</label>
        <DatePicker
          value={startDate}
          onChange={setStartDate}
          calendar={persian}
          locale={persian_fa}
          calendarPosition="bottom-right"
          className="p-2 rounded bg-gray-700 text-black"
        />
      </div>
      <div>
        <label className="text-white block mb-2">تا تاریخ:</label>
        <DatePicker
          value={endDate}
          onChange={setEndDate}
          calendar={persian}
          locale={persian_fa}
          calendarPosition="bottom-right"
          className="p-2 rounded bg-gray-700 text-black"
        />
      </div>
      <div>
        <label className="text-white block mb-2">وضعیت:</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="p-2 rounded bg-gray-700 text-white"
        >
          <option value="">همه</option>
          {statusOptions.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleApplyFilters}
        className="bg-blue-600 hover:bg-blue-700 text-white p-3 px-5 rounded -mb-7 mr-4"
      >
        اعمال فیلترها
      </button>

    </div>
  );
};

export default Filters;
