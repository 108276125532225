import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";
import toast from "react-hot-toast";
import Pagination from "../../components/Pagination";
import Filters from "../../components/Filters";

const Posts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [postsList, setPostsList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // 
  const [filters, setFilters] = useState({ startDate: "", endDate: "", status: "" });

  const getPostList = (page = 1) => {
    setLoading(true);

    // 
    const { startDate, endDate, status } = filters;
    const config = {
      url: `${BaseUrl}/posts?limit=10&page=${page}${startDate ? "&startDate=" + startDate : ""}${endDate ? "&endDate=" + endDate : ""}&status=${status}`,
      method: "GET",
    };
    axios(config)
      .then((res) => {
        setPostsList(res?.data?.posts);
        setTotalPages(res?.data?.totalPages);  // دریافت تعداد صفحات از API
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getPostList(currentPage);
  }, [currentPage, filters]);

  const togglePostAccept = (_id, user_id, status) => {
    const data = {
      id: _id,
      user_id,
      status: status == "accept" ? "reject" : "accept"
    };
    axios
      .post(`${BaseUrl}/posts/accept`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        try {
          toast.success(response?.data?.message)
          getPostList()
        } catch (error) {
          if (
            error?.response?.data?.error
          ) {
            toast.error(error.response.data.error);
          } else {
            // نمایش پیام خطا برای سایر موارد
            toast.error("خطای ناشناخته");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        toast.show(error?.response?.data?.message, {
          type: "danger",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "slide-in",
        });
      });
  }
  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto">
        <div className="flex h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
            <Filters
              statusOptions={[
                { key: "pending", label: "درانتظار تایید" },
                { key: "accept", label: "منتشر شده" },
                { key: "reject", label: "رد شده" },
              ]}
              onFilterChange={setFilters}
            />
            <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
              <div className="flex-1 w-full flex items-center h-11 px-5">
                <div className="col-span-1 w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    تصویر
                  </p>
                </div>
                <div className="col-span-1 w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نام کاربری
                  </p>
                </div>
                <div className="col-span-1  w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نوع کاربری
                  </p>
                </div>
                <div className="col-span-1  w-[12%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نام و نام خانوادگی
                  </p>
                </div>
                <div className="col-span-1  w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    موبایل
                  </p>
                </div>
                <div className="col-span-1  w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    کد ملی
                  </p>
                </div>
                <div className="col-span-1  w-[12%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    تاریخ ثبت پست
                  </p>
                </div>
                <div className="col-span-1  w-[16%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    وضعیت
                  </p>
                </div>
                <div className="col-span-1 w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium"></p>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-full flex items-center justify-center mt-5">
                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
              </div>
            ) : (
              <div
                className="overflow-y-scroll hide-scrollbar w-full"
              // style={{ maxHeight: maxHeightTable }}
              >
                {postsList?.map((post, index) => (
                  <div className="w-full mt-4 flex items-center bg-[#252525]">
                    <div
                      className={`flex-1 w-full flex items-center h-[90px] px-5  rounded-lg  py-8 ${index % 2 == 0
                        ? "bg-[#c0e2f04b]"
                        : "bg-[#678fa062]"
                        }`}
                    >
                      <div className="col-span-1 w-[10%]">
                        <div className="flex items-center">
                          <img
                            className="w-[80px] h-[80px] rounded-md ml-2"
                            src={
                              ImageBaseUrl +
                              post?.post_image
                            }
                            alt=""
                          />

                        </div>
                      </div>
                      <div className="col-span-1 w-[10%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {post?.user_name}
                        </p>
                      </div>
                      <div className="col-span-1 w-[10%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {post?.user_type ===
                            "employer"
                            ? "کارفرما"
                            : post?.user_type ===
                              "user"
                              ? post?.expertise
                              : ""}
                        </p>
                      </div>
                      <div className="col-span-1 w-[12%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          <p className="font-KalamehMed text-sm font-medium">
                            {post?.first_name}{" "}
                            {post?.last_name}
                          </p>
                        </p>
                      </div>
                      <div className="col-span-1 w-[10%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {post?.mobile}
                        </p>
                      </div>
                      <div className="col-span-1 w-[10%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {post?.national_code}
                        </p>
                      </div>
                      <div className="col-span-1 w-[12%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {post?.createdAt}
                        </p>
                      </div>
                      <div className="col-span-1 w-[16%]">
                        <div className="flex items-center">
                          <p className={`font-KalamehMed text-sm font-medium ${post?.status === "accept"
                            ? "text-green-300"
                            :
                            post?.status === "pending" ?
                              "text-yellow-300" :
                              "text-red-300"}`}>
                            {post?.status === "accept"
                              ? "منتشر شده"
                              :
                              post?.status === "pending" ?
                                "در انتظار تایید" :
                                "رد شده"}
                          </p>
                          {/* {post?.status === "accept" ? (
                                                        <button onClick={() => togglePostAccept(post?._id, post?.user_id, post?.status)} className="bg-[#a02121d3] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3">
                                                            رد کردن
                                                        </button>
                                                    ) : (
                                                        <button onClick={() => togglePostAccept(post?._id, post?.user_id, post?.status)} className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3">
                                                            انتشار
                                                        </button>
                                                    )} */}
                        </div>
                      </div>
                      <div className="col-span-1 w-[10%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          <button
                            onClick={() => {
                              navigate(
                                `${post?._id}`
                              );
                            }}
                            className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                          >
                            جزئیات
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Pagination
              totalItems={postsList.length}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}  // تغییر صفحه
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Posts;
