import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";
import Pagination from "../../components/Pagination";
import Filters from "../../components/Filters";

const Vitrine = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vitrineList, setVitrineList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // 
  const [filters, setFilters] = useState({ startDate: "", endDate: "", status: "" });

  const getVitrineList = (page = 1) => {
    setLoading(true);
    // 
    const { startDate, endDate, status } = filters;

    const config = {
      url: `${BaseUrl}/advertising?limit=20&page=${page}${startDate ? "&startDate=" + startDate : ""}${endDate ? "&endDate=" + endDate : ""}&status=${status}`,
      method: "GET",
    };
    axios(config)
      .then((res) => {
        setVitrineList(res?.data?.advertisings);
        setTotalPages(res?.data?.totalPages);  // دریافت تعداد صفحات از API
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getVitrineList(currentPage);
  }, [currentPage, filters]);
  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto">
        <div className="flex h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
            <Filters
              statusOptions={[
                { key: "pre_payment", label: "در انتظار پرداخت" },
                { key: "paid", label: "پرداخت شده" },
                { key: "accepted", label: "تایید شده" },
                { key: "rejected", label: "رد شده" },
                { key: "expired", label: "منقضی شده" },
              ]}
              onFilterChange={setFilters}
            />
            <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
              <div className="flex-1 w-full flex items-center h-11 px-5">
                <div className="col-span-1 w-[13%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نام و نام خانوادگی کاربر
                  </p>
                </div>
                <div className="col-span-1  w-[13%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    دسته بندی
                  </p>
                </div>
                <div className="col-span-1  w-[13%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    عنوان تبلیغ
                  </p>
                </div>
                <div className="col-span-1  w-[13%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    استان
                  </p>
                </div>
                <div className="col-span-1  w-[13%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    شهر
                  </p>
                </div>
                <div className="col-span-1  w-[13%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    محله
                  </p>
                </div>
                <div className="col-span-1  w-[12%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    وضعیت
                  </p>
                </div>
                <div className="col-span-1 w-[11%]">
                  <p className="font-KalamehMed text-sm font-medium"></p>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-full flex items-center justify-center mt-5">
                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
              </div>
            ) : (
              <div
                className="overflow-y-scroll hide-scrollbar w-full"
              // style={{ maxHeight: maxHeightTable }}
              >
                {vitrineList?.map((vitrine, index) => (
                  <div className="w-full mt-4 flex items-center bg-[#252525]">
                    <div
                      className={`flex-1 w-full flex items-center h-[90px] px-5  rounded-lg  py-8 ${index % 2 == 0
                        ? "bg-[#c0e2f04b]"
                        : "bg-[#678fa062]"
                        }`}
                    >
                      <div className="col-span-1 w-[13%]">
                        <p className="font-KalamehMed text-sm font-medium mr-2">
                          {
                            vitrine?.creator_id
                              ?.first_name
                          }{" "}
                          {
                            vitrine?.creator_id
                              ?.last_name
                          }
                        </p>
                      </div>
                      <div className="col-span-1 w-[13%]">
                        <p className="font-KalamehMed text-sm font-medium mr-2">
                          {vitrine?.category}
                        </p>
                      </div>
                      <div className="col-span-1 w-[13%]">
                        <p className="font-KalamehMed text-sm font-medium mr-2">
                          {vitrine?.title}
                        </p>
                      </div>
                      <div className="col-span-1 w-[13%]">
                        <p className="font-KalamehMed text-sm font-medium mr-2">
                          {vitrine?.province?.name}
                        </p>
                      </div>
                      <div className="col-span-1 w-[13%]">
                        <p className="font-KalamehMed text-sm font-medium mr-2">
                          {vitrine?.city?.name}
                        </p>
                      </div>
                      <div className="col-span-1 w-[13%]">
                        <p className="font-KalamehMed text-sm font-medium mr-2">
                          {vitrine?.neighbourhood}
                        </p>
                      </div>
                      <div className="col-span-1 w-[12%]">
                        <div className="flex items-center">
                          <p
                            className={`font-KalamehMed text-sm font-medium ${vitrine?.status ===
                              "pre_payment"
                              ? "text-yellow-400"
                              : vitrine?.status ==
                                "paid"
                                ? "text-green-400"
                                : vitrine?.status ==
                                  "accepted"
                                  ? "text-green-400"
                                  : "text-red-400"
                              }
                                        `}
                          >
                            {vitrine?.status ===
                              "pre_payment"
                              ? "در انتظار پرداخت"
                              : vitrine?.status ==
                                "paid"
                                ? "پرداخت شده"
                                : vitrine?.status ==
                                  "accepted"
                                  ? "تایید شده"
                                  : vitrine?.status ==
                                    "rejected"
                                    ? "رد شده"
                                    : "منقضی شده"}
                          </p>
                        </div>
                      </div>
                      <div className="col-span-1 w-[11%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          <button
                            onClick={() => {
                              navigate(
                                `${vitrine?._id}`
                              );
                            }}
                            className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                          >
                            جزئیات
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Pagination
              totalItems={vitrineList.length}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}  // تغییر صفحه
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Vitrine;
