import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "../utils/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/users/Users";
import Posts from "../pages/posts/Posts";
import Projects from "../pages/projects/Projects";
import Login from "../pages/Login";
import UserDetail from "../pages/users/UserDetail";
import PostDetail from "../pages/posts/PostDetail";
import ProjectDetail from "../pages/projects/ProjectDetail";
import Tickets from "../pages/tickets/Tickets";
import TicketDetail from "../pages/tickets/TicketDetail";
import FinancialDetail from "../pages/financials/FinancialDetail";
import Financials from "../pages/financials/Financials";
import Comments from "../pages/comments/Comments";
import CommentDetail from "../pages/comments/CommentDetail";
import Vitrine from "../pages/vitrine/vitrine";
import VitrineDetail from "../pages/vitrine/VitrineDetail";
import VitrineComments from "../pages/vitrineComments/VitrineComments";
import VitrineCommentDetail from "../pages/vitrineComments/VitrineCommentDetail";
import Shops from "../pages/shops/Shops";
import ShopDetail from "../pages/shops/ShopDetail";
import TermsAndConditionsEditor from "../pages/terms/TermsAndConditionsEditor";
import Version from "../pages/version/Version"
import Expertise from "../pages/expertise/Expertise";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/users",
    element: (
      <ProtectedRoute>
        <Users />
      </ProtectedRoute>
    ),
  },
  {
    path: "users/:id",
    element: (
      <ProtectedRoute>
        <UserDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/posts",
    element: (
      <ProtectedRoute>
        <Posts />
      </ProtectedRoute>
    ),
  },
  {
    path: "posts/:id",
    element: (
      <ProtectedRoute>
        <PostDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/projects",
    element: (
      <ProtectedRoute>
        <Projects />
      </ProtectedRoute>
    ),
  },
  {
    path: "projects/:id",
    element: (
      <ProtectedRoute>
        <ProjectDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/tickets",
    element: (
      <ProtectedRoute>
        <Tickets />
      </ProtectedRoute>
    ),
  },
  {
    path: "tickets/:id/:status",
    element: (
      <ProtectedRoute>
        <TicketDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/financials",
    element: (
      <ProtectedRoute>
        <Financials />
      </ProtectedRoute>
    ),
  },
  {
    path: "financials/:id",
    element: (
      <ProtectedRoute>
        <FinancialDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/comments",
    element: (
      <ProtectedRoute>
        <Comments />
      </ProtectedRoute>
    ),
  },
  {
    path: "comments/:id",
    element: (
      <ProtectedRoute>
        <CommentDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/vitrine",
    element: (
      <ProtectedRoute>
        <Vitrine />
      </ProtectedRoute>
    ),
  },
  {
    path: "vitrine/:id",
    element: (
      <ProtectedRoute>
        <VitrineDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/vitrine-comments",
    element: (
      <ProtectedRoute>
        <VitrineComments />
      </ProtectedRoute>
    ),
  },
  {
    path: "vitrine-comments/:id",
    element: (
      <ProtectedRoute>
        <VitrineCommentDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/shops",
    element: (
      <ProtectedRoute>
        <Shops />
      </ProtectedRoute>
    ),
  },
  {
    path: "shops/:id",
    element: (
      <ProtectedRoute>
        <ShopDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/terms",
    element: (
      <ProtectedRoute>
        <TermsAndConditionsEditor />
      </ProtectedRoute>
    ),
  }, {
    path: "/version",
    element: (
      <ProtectedRoute>
        <Version />
      </ProtectedRoute>
    ),
  }, {
    path: "/expertise",
    element: (
      <ProtectedRoute>
        <Expertise />
      </ProtectedRoute>
    ),
  },
]);
