import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import Loading from "../../components/elements/loading";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import Filters from "../../components/Filters";

const Users = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // 
  const [filters, setFilters] = useState({ startDate: "", endDate: "", status: "" });


  const getUserList = (page = 1) => {
    setLoading(true);

    // 
    const { startDate, endDate, status } = filters;
    const config = {
      url: `${BaseUrl}/users?limit=20&page=${page}${startDate ? "&startDate=" + startDate : ""}${endDate ? "&endDate=" + endDate : ""}&status=${status}`,
      method: "GET",
    };

    axios(config)
      .then((res) => {
        setUsersList(res?.data?.users);
        setTotalPages(res?.data?.totalPages);  // دریافت تعداد صفحات از API
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  // 
  useEffect(() => {
    getUserList(currentPage);
  }, [currentPage, filters]);

  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto">
        <div className="flex h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
            <Filters
              statusOptions={[
                { key: "none", label: "بدون مدارک" },
                { key: "pending", label: "درانتظار تایید" },
                { key: "verified", label: "تایید شده" },
                { key: "rejected", label: "رد شده" },
              ]}
              onFilterChange={setFilters}
            />
            <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
              <div className="flex-1 w-full flex items-center h-11 px-5">
                <div className="col-span-1 w-[12%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نام و نام خانوادگی
                  </p>
                </div>
                <div className="col-span-1 w-[8%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نام کاربری
                  </p>
                </div>
                <div className="col-span-1  w-[6%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    نوع کاربری
                  </p>
                </div>
                <div className="col-span-1  w-[8%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    موبایل
                  </p>
                </div>
                <div className="col-span-1  w-[8%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    کد ملی
                  </p>
                </div>
                <div className="col-span-1  w-[5%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    استان
                  </p>
                </div>
                <div className="col-span-1  w-[5%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    شهر
                  </p>
                </div>
                <div className="col-span-1  w-[12%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    آخرین زمان ورود
                  </p>
                </div>
                <div className="col-span-1  w-[12%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    تاریخ ثبت نام
                  </p>
                </div>
                <div className="col-span-1  w-[14%]">
                  <p className="font-KalamehMed text-sm font-medium">
                    وضعیت
                  </p>
                </div>
                <div className="col-span-1 w-[10%]">
                  <p className="font-KalamehMed text-sm font-medium"></p>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-full flex items-center justify-center mt-5">
                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
              </div>
            ) : (
              <div
                className="overflow-y-scroll hide-scrollbar w-full"
              // style={{ maxHeight: maxHeightTable }}
              >
                {usersList?.map((user, index) => (
                  <div className="w-full mt-4 flex items-center bg-[#252525]">
                    <div
                      className={`flex-1 w-full flex items-center h-11 px-5  rounded-lg  py-8 ${index % 2 == 0
                        ? "bg-[#c0e2f04b]"
                        : "bg-[#678fa062]"
                        }`}
                    >
                      <div className="col-span-1 w-[12%]">
                        <div className="flex items-center">
                          <img
                            className="w-[50px] h-[50px] rounded-md ml-2"
                            src={
                              ImageBaseUrl +
                              user?.profile_image
                            }
                            alt=""
                          />
                          <p className="font-KalamehMed text-sm font-medium">
                            {user?.first_name}{" "}
                            {user?.last_name}
                          </p>
                        </div>
                      </div>
                      <div className="col-span-1 w-[8%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.user_name}
                        </p>
                      </div>
                      <div className="col-span-1 w-[6%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.user_type ===
                            "employer"
                            ? "کارفرما"
                            : user?.user_type ===
                              "user"
                              ? user?.expertise
                              : ""}
                        </p>
                      </div>
                      <div className="col-span-1 w-[8%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.mobile}
                        </p>
                      </div>
                      <div className="col-span-1 w-[8%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.national_code}
                        </p>
                      </div>
                      <div className="col-span-1 w-[5%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.province?.name}
                        </p>
                      </div>
                      <div className="col-span-1 w-[5%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.city?.name}
                        </p>
                      </div>
                      <div className="col-span-1 w-[12%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.last_online}
                        </p>
                      </div>
                      <div className="col-span-1 w-[12%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          {user?.createdAt}
                        </p>
                      </div>
                      <div className="col-span-1 w-[14%]">
                        <div className="flex items-center">
                          <p className="font-KalamehMed text-sm font-medium">
                            {user?.blocked
                              ? "غیر فعال"
                              : "فعال"}
                          </p>
                          {user?.blocked ? (
                            <button className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3">
                              تایید
                            </button>
                          ) : (
                            <button className="bg-[#a02121d3] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3">
                              غیر فعال
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-span-1 w-[10%]">
                        <p className="font-KalamehMed text-sm font-medium">
                          <button
                            onClick={() => {
                              navigate(
                                `${user?._id}`
                              );
                            }}
                            className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                          >
                            جزئیات
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Pagination
              totalItems={usersList.length}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}  // تغییر صفحه
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
