import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BaseUrl } from '../../components/BaseUrl';
import axios from 'axios';
import AdminHeader from '../../components/admin-header';
import Sidebar from '../../components/sidebar';
import Loading from '../../components/elements/loading';

const FinancialDetail = () => {
  const navigate = useNavigate();
  const param = useParams();
  const id = param?.id;
  const [loading, setLoading] = useState(false);
  const [financialDetails, setFinancialDetails] = useState([]);

  const getFinancialDetails = (page = 1) => {
    setLoading(true);
    const config = {
      url: `${BaseUrl}/financial/detail?financial_id=${id}`,
      method: "GET",
    };
    axios(config)
      .then((res) => {
        setFinancialDetails(res?.data?.financial);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getFinancialDetails();
  }, [id]);
  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto">
        <div className="flex h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
            <div className="w-full flex items-center justify-between">
              <h4 className="font-medium font-KalamehMed text-lg">
                اطلاعات تراکنش:
              </h4>
              <button
                onClick={() => navigate(-1)}
                className="flex gap-1  bg-[#1f5f79] font-semibold font-KalamehSemi rounded-lg hover:bg-[#E0E3E3] transition-colors duration-500 p-[10px] "
              >
                بازگشت
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.56994 19.3191C9.37994 19.3191 9.18994 19.2491 9.03994 19.0991L2.96994 13.0291C2.67994 12.7391 2.67994 12.2591 2.96994 11.9691L9.03994 5.89914C9.32994 5.60914 9.80994 5.60914 10.0999 5.89914C10.3899 6.18914 10.3899 6.66914 10.0999 6.95914L4.55994 12.4991L10.0999 18.0391C10.3899 18.3291 10.3899 18.8091 10.0999 19.0991C9.95994 19.2491 9.75994 19.3191 9.56994 19.3191Z"
                    fill="#fff"
                  />
                  <path
                    d="M20.4999 13.25H3.66992C3.25992 13.25 2.91992 12.91 2.91992 12.5C2.91992 12.09 3.25992 11.75 3.66992 11.75H20.4999C20.9099 11.75 21.2499 12.09 21.2499 12.5C21.2499 12.91 20.9099 13.25 20.4999 13.25Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>
            {loading ? (
              <div className="w-full flex items-center justify-center mt-5">
                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
              </div>
            ) : (
              <div className="flex gap-3  ">
                <div className="flex flex-col gap-5 pr-5 mt-5">
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      عنوان پروژه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.project_id?.title}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام کاربری پرداخت کننده:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.user_id?.user_name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام و نام خانوادگی پرداخت کننده:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.user_id?.first_name}
                      {" "}
                      {financialDetails?.user_id?.last_name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      مبلغ پرداخت  شده:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {Number(financialDetails?.amount).toLocaleString()}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      شبا کارفرما:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      ننوشتم که اشتباه به این نفرستیم
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نوبت پرداخت:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.type === "create" ?
                        financialDetails?.project_id?.project_type == "normal" ?
                          "ایجاد پروژه _ معمولی" : financialDetails?.project_id?.project_type == "force" ?
                            "ایجاد پروژه _ فوری" : "ایجاد پروژه _ هایلایت"
                        :
                        financialDetails?.type === "installment" ?
                          financialDetails?.installment_step == "1" ?
                            "پرداخت بخش اول" :
                            financialDetails?.installment_step == "2" ?
                              "پرداخت بخش دوم" :
                              financialDetails?.installment_step == "3" ?
                                "پرداخت بخش سوم" :
                                financialDetails?.installment_step == "all" ?
                                  "پرداخت یکجای بخش 2 و 3" : ""
                          : ""
                      }
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام کاربری کاربر دریافت کننده:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.project_id?.selected_user?.user_name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام و نام خانوادگی کاربر دریافت کننده:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.project_id?.selected_user?.first_name}
                      {" "}
                      {financialDetails?.project_id?.selected_user?.last_name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      شبا کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.project_id?.selected_user?.shaba}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      مبلغ قابل پرداخت برای کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {Number(financialDetails?.amount - financialDetails?.amount * 0.15)}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      وضعیت پرداختی:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {financialDetails?.type === "create" ?
                        financialDetails?.project_id?.project_type == "normal" ?
                          "ایجاد پروژه _ معمولی" : financialDetails?.project_id?.project_type == "force" ?
                            "ایجاد پروژه _ فوری" : "ایجاد پروژه _ هایلایت"
                        :
                        financialDetails?.type === "installment" ?
                          financialDetails?.installment_step == "1" ?
                            "پرداخت بخش اول" :
                            financialDetails?.installment_step == "2" ?
                              "پرداخت بخش دوم" :
                              financialDetails?.installment_step == "3" ?
                                "پرداخت بخش سوم" :
                                financialDetails?.installment_step == "all" ?
                                  "پرداخت یکجای بخش 2 و 3" : ""
                          : ""
                      }
                      <p className={`font-KalamehMed text-sm font-medium mr-4 ${financialDetails?.status === "successful" ?
                        "text-green-400" :
                        "text-red-400"}`}>
                        {financialDetails?.status === "successful" ?
                          "موفق" :
                          "ناموفق"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      تاریخ پرداخت:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      <div className="flex items-center">
                        {
                          financialDetails?.createdAt
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default FinancialDetail