import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../components/BaseUrl';
import AdminHeader from '../../components/admin-header';
import Sidebar from '../../components/sidebar';

function Expertise() {
  const [expertises, setExpertises] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    expertise: '',
    subExpertise: '',
  });

  useEffect(() => {
    // دریافت تخصص‌ها و زیر تخصص‌ها
    setLoading(true);
    axios
      .get(`${BaseUrl}/expertise`)
      .then((response) => {
        setExpertises(response.data.expertises);
        setLoading(false);
      })
      .catch((err) => {
        setError('خطا در دریافت اطلاعات');
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitExpertise = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    axios
      .post(`${BaseUrl}/expertise/create`, { expertise: formData.expertise })
      .then((response) => {
        setExpertises([...expertises, { expertise: formData.expertise, sub_expertise: [] }]);
        setFormData({ ...formData, expertise: '' });
        alert('تخصص با موفقیت اضافه شد');
        setLoading(false);
      })
      .catch((error) => {
        setError('خطا در ذخیره تخصص');
        setLoading(false);
      });
  };

  const handleSubmitSubExpertise = (e, expertiseId) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    axios
      .post(`${BaseUrl}/expertise/create/sub?id=${expertiseId}`, { name: formData.subExpertise })
      .then((response) => {
        const updatedExpertises = expertises.map((exp) =>
          exp._id === expertiseId
            ? { ...exp, sub_expertise: [...exp.sub_expertise, { name: formData.subExpertise }] }
            : exp
        );
        setExpertises(updatedExpertises);
        setFormData({ ...formData, subExpertise: '' });
        alert('زیرتخصص با موفقیت اضافه شد');
        setLoading(false);
      })
      .catch((error) => {
        setError('خطا در ذخیره زیرتخصص');
        setLoading(false);
      });
  };

  const handleEditExpertise = (id, newExpertise) => {
    setLoading(true);
    axios
      .put(`${BaseUrl}/expertise/update/${id}`, { expertise: newExpertise })
      .then((response) => {
        const updatedExpertises = expertises.map((exp) =>
          exp._id === id ? { ...exp, expertise: newExpertise } : exp
        );
        setExpertises(updatedExpertises);
        alert('تخصص با موفقیت ویرایش شد');
        setLoading(false);
      })
      .catch((error) => {
        setError('خطا در ویرایش تخصص');
        setLoading(false);
      });
  };

  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto flex flex-col bg-gray-900">
        <div className="flex flex-1 h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-6 mb-11 mx-auto bg-[#252525] text-white relative rounded-xl shadow-lg">
            <h1 className="text-2xl font-bold mb-6">مدیریت تخصص‌ها و زیرتخصص‌ها</h1>

            {/* نمایش خطا */}
            {error && <p className="text-red-500 mb-4">{error}</p>}

            {/* فرم افزودن تخصص جدید */}
            <form onSubmit={handleSubmitExpertise} className="mb-4 flex items-center space-x-4">
              <input
                type="text"
                name="expertise"
                value={formData.expertise}
                onChange={handleChange}
                placeholder="نام تخصص"
                required
                className="p-2 w-1/3 bg-gray-800 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1f5f79]"
              />
              <button
                type="submit"
                disabled={loading}
                className="p-2 bg-green-600 text-white rounded-md disabled:opacity-50"
              >
                {loading ? 'در حال بارگذاری...' : 'افزودن تخصص'}
              </button>
            </form>

            {/* لیست تخصص‌ها */}
            {loading ? (
              <p>در حال بارگذاری...</p>
            ) : (
              <div>
                {expertises.map((exp) => (
                  <div key={exp._id} className="mb-6 p-4 bg-gray-800 rounded-md shadow-md">
                    <h3 className="text-xl font-semibold">{exp.expertise}</h3>

                    {/* ویرایش تخصص */}
                    {/* <button
                      onClick={() => handleEditExpertise(exp._id, prompt('نام جدید تخصص', exp.expertise))}
                      className="mt-2 p-1 bg-yellow-500 text-white rounded-md"
                    >
                      ویرایش تخصص
                    </button> */}

                    {/* فرم افزودن زیر تخصص */}
                    <form onSubmit={(e) => handleSubmitSubExpertise(e, exp._id)} className="mt-4 flex items-center space-x-4">
                      <input
                        type="text"
                        name="subExpertise"
                        value={formData.subExpertise}
                        onChange={handleChange}
                        placeholder="نام زیرتخصص"
                        required
                        className="p-2 w-1/3 bg-gray-800 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1f5f79]"
                      />
                      <button
                        type="submit"
                        disabled={loading}
                        className="p-2 bg-blue-600 text-white rounded-md disabled:opacity-50"
                      >
                        {loading ? 'در حال بارگذاری...' : 'افزودن زیرتخصص'}
                      </button>
                    </form>

                    {/* لیست زیر تخصص‌ها */}
                    {exp.sub_expertise.length > 0 && (
                      <ul className="mt-4">
                        {exp.sub_expertise.map((sub) => (
                          <li key={sub._id} className="text-sm text-gray-300">
                            {sub.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Expertise;
