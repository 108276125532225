import React, { useEffect, useState } from 'react';
import AdminHeader from '../../components/admin-header';
import Sidebar from '../../components/sidebar';
import axios from 'axios';
import { BaseUrl } from '../../components/BaseUrl';

function Version() {
  const [version, setVersion] = useState(null); // اطلاعات نسخه فعلی
  const [loading, setLoading] = useState(false); // حالت بارگذاری
  const [error, setError] = useState(null); // خطای احتمالی
  const [formData, setFormData] = useState({
    version: '',
    mandatory: false,
    releaseNotes: '',
    updateUrls: '',
  }); // اطلاعات فرم

  // دریافت نسخه فعلی از سرور
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BaseUrl}/version`)
      .then((response) => {
        setVersion(response.data);
        setFormData({
          version: response.data.version || '',
          mandatory: response.data.mandatory || false,
          releaseNotes: response.data.releaseNotes || '',
          updateUrls: response.data.updateUrls ? response.data.updateUrls.join(', ') : '',
        });
        setLoading(false);
      })
      .catch((error) => {
        setError('خطا در دریافت اطلاعات نسخه');
        setLoading(false);
      });
  }, []);

  // مدیریت تغییرات فرم
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // ارسال اطلاعات به سرور
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // تبدیل `updateUrls` به آرایه
    const formattedData = {
      ...formData,
      updateUrls: formData.updateUrls.split(',').map((url) => url.trim()),
    };

    axios
      .post(`${BaseUrl}/version`, formattedData)
      .then((response) => {
        setVersion(response.data.version);
        setLoading(false);
        alert('نسخه با موفقیت ذخیره شد.');
      })
      .catch((error) => {
        setError('خطا در ذخیره اطلاعات نسخه');
        setLoading(false);
      });
  };

  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto flex flex-col bg-gray-900">
        <div className="flex flex-1 h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-6 mb-11 mx-auto bg-[#252525] text-white relative rounded-xl shadow-lg">
            <h1 className="text-3xl font-extrabold mb-6 text-center text-[#00cc99]">مدیریت ورژن برنامه</h1>

            {/* نمایش خطا */}
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

            {/* حالت بارگذاری */}
            {loading ? (
              <div className="flex justify-center items-center h-48">
                <p className="text-xl text-gray-300">در حال بارگذاری...</p>
              </div>
            ) : (
              <>
                {/* نمایش اطلاعات نسخه */}
                {version && (
                  <div className="mb-8 p-4 border border-gray-700 rounded-lg bg-gray-800">
                    <h2 className="text-lg font-semibold text-[#00cc99] mb-2">نسخه فعلی:</h2>
                    <p className="text-sm"><strong>ورژن:</strong> {version.version}</p>
                    <p className="text-sm"><strong>اجباری:</strong> {version.mandatory ? 'بله' : 'خیر'}</p>
                    <p className="text-sm"><strong>توضیحات:</strong> {version.releaseNotes}</p>
                    <p className="text-sm"><strong>لینک‌ها:</strong> {version.updateUrls.join(', ')}</p>
                  </div>
                )}

                <hr className="border-gray-700 my-8" />

                <h1 className="text-2xl font-bold mb-6 text-center text-[#00cc99]">فرم ایجاد/ویرایش نسخه</h1>
                <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg shadow-md">
                  <div>
                    <label htmlFor="version" className="block mb-1 text-gray-300">
                      نسخه:
                    </label>
                    <input
                      type="text"
                      id="version"
                      name="version"
                      value={formData.version}
                      onChange={handleChange}
                      className="w-full p-3 rounded bg-gray-900 text-white focus:ring-2 focus:ring-[#00cc99] focus:outline-none"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="mandatory" className="inline-flex items-center text-gray-300">
                      <input
                        type="checkbox"
                        id="mandatory"
                        name="mandatory"
                        checked={formData.mandatory}
                        onChange={handleChange}
                        className="mr-2 h-4 w-4 text-[#00cc99] focus:ring-[#00cc99]"
                      />
                      اجباری
                    </label>
                  </div>

                  <div>
                    <label htmlFor="releaseNotes" className="block mb-1 text-gray-300">
                      توضیحات نسخه:
                    </label>
                    <textarea
                      id="releaseNotes"
                      name="releaseNotes"
                      value={formData.releaseNotes}
                      onChange={handleChange}
                      className="w-full p-3 rounded bg-gray-900 text-white focus:ring-2 focus:ring-[#00cc99] focus:outline-none"
                      rows="4"
                    ></textarea>
                  </div>

                  <div>
                    <label htmlFor="updateUrls" className="block mb-1 text-gray-300">
                      لینک‌های آپدیت (جدا شده با کاما):
                    </label>
                    <input
                      type="text"
                      id="updateUrls"
                      name="updateUrls"
                      value={formData.updateUrls}
                      onChange={handleChange}
                      className="w-full p-3 rounded bg-gray-900 text-white focus:ring-2 focus:ring-[#00cc99] focus:outline-none"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-[#00cc99] text-black py-3 rounded-lg font-bold hover:bg-[#00b388] transition duration-200"
                  >
                    ذخیره
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Version;
