import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";
import toast from "react-hot-toast";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "fixed",
  },
};
const ProjectDetail = () => {
  const navigate = useNavigate();
  const param = useParams();
  const id = param?.id;
  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const openModal = (image) => {
    setModalImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getProjectDetails = (page = 1) => {
    setLoading(true);
    const config = {
      url: `${BaseUrl}/projects/detail?project_id=${id}`,
      method: "GET",
    };
    axios(config)
      .then((res) => {
        setProjectDetails(res?.data?.project);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getProjectDetails();
  }, [id]);

  const acceptProjectAccept = (_id, user_name) => {
    const data = {
      id: _id,
      user_name,
    };
    axios
      .post(`${BaseUrl}/projects/accept`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        try {
          toast.success(response?.data?.message);
          getProjectDetails();
        } catch (error) {
          if (error?.response?.data?.error) {
            toast.error(error.response.data.error);
          } else {
            // نمایش پیام خطا برای سایر موارد
            toast.error("خطای ناشناخته");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        toast.show(error?.response?.data?.message, {
          type: "danger",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "slide-in",
        });
      });
  };
  const rejectProjectAccept = (_id, user_id) => {
    const data = {
      id: _id,
      user_id,
      rejectReason,
    };
    axios
      .post(`${BaseUrl}/projects/reject`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        try {
          toast.success(response?.data?.message);
          getProjectDetails();
        } catch (error) {
          if (error?.response?.data?.error) {
            toast.error(error.response.data.error);
          } else {
            // نمایش پیام خطا برای سایر موارد
            toast.error("خطای ناشناخته");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        toast.show(error?.response?.data?.message, {
          type: "danger",
          placement: "top",
          duration: 4000,
          offset: 30,
          animationType: "slide-in",
        });
      });
  };
  return (
    <>
      <AdminHeader />
      <div className="min-h-screen mx-auto">
        <div className="flex h-full w-full">
          <div className="bg-[#1f5f79]">
            <Sidebar />
          </div>
          <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
            <div className="w-full flex items-center justify-between">
              <h4 className="font-medium font-KalamehMed text-lg">
                اطلاعات پروژه:
              </h4>
              <button
                onClick={() => navigate(-1)}
                className="flex gap-1  bg-[#1f5f79] font-semibold font-KalamehSemi rounded-lg hover:bg-[#E0E3E3] transition-colors duration-500 p-[10px] "
              >
                بازگشت
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.56994 19.3191C9.37994 19.3191 9.18994 19.2491 9.03994 19.0991L2.96994 13.0291C2.67994 12.7391 2.67994 12.2591 2.96994 11.9691L9.03994 5.89914C9.32994 5.60914 9.80994 5.60914 10.0999 5.89914C10.3899 6.18914 10.3899 6.66914 10.0999 6.95914L4.55994 12.4991L10.0999 18.0391C10.3899 18.3291 10.3899 18.8091 10.0999 19.0991C9.95994 19.2491 9.75994 19.3191 9.56994 19.3191Z"
                    fill="#fff"
                  />
                  <path
                    d="M20.4999 13.25H3.66992C3.25992 13.25 2.91992 12.91 2.91992 12.5C2.91992 12.09 3.25992 11.75 3.66992 11.75H20.4999C20.9099 11.75 21.2499 12.09 21.2499 12.5C21.2499 12.91 20.9099 13.25 20.4999 13.25Z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>
            {loading ? (
              <div className="w-full flex items-center justify-center mt-5">
                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
              </div>
            ) : (
              <div className="flex gap-3  ">
                <div className="flex flex-col gap-5 pr-5 mt-5">
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="post-image-modal"
                    style={customStyles}
                  >
                    {/* استفاده از ImageMagnify */}
                    <img
                      src={modalImage}
                      alt="Project"
                      className="post-modal-image"
                    />
                  </Modal>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      عنوان پروژه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.title}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      توضیحات پروژه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.description}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام کارفرما:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {
                        projectDetails?.creator
                          ?.first_name
                      }{" "}
                      {projectDetails?.creator?.last_name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام کاربری کارفرما:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.creator?.user_name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      موبایل کارفرما:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.creator?.mobile}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {
                        projectDetails?.selected_user
                          ?.first_name
                      }{" "}
                      {
                        projectDetails?.selected_user
                          ?.last_name
                      }
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نام کاربری کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {
                        projectDetails?.selected_user
                          ?.user_name
                      }
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      موبایل کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {
                        projectDetails?.selected_user
                          ?.mobile
                      }
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      استان محل اجرای پروژه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.province?.name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      شهر محل اجرای پروژه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.city?.name}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      دسته بندی اصلی:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.expertise}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      نوع تخصصها:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.sub_expertise?.map(
                        (item, index) => {
                          return (
                            item +
                            (index !==
                              projectDetails
                                ?.sub_expertise
                                ?.length -
                              1
                              ? " _ "
                              : "")
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      زمان پیشنهادی:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.offer_time}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      مبلغ پیشنهادی:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {Number(
                        projectDetails?.offer_price
                      ).toLocaleString()}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      زمان نهایی:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.final_time}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      مبلغ نهایی:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {Number(
                        projectDetails?.final_price
                      ).toLocaleString()}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      تاریخ ثبت درخواست:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.createdAt}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      وضعیت:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      <div className="flex items-center">
                        <p
                          className={`font-KalamehMed text-sm font-medium ${projectDetails?.status ===
                              "accept"
                              ? "text-green-300"
                              : projectDetails?.status ===
                                "pre_payment" ||
                                projectDetails?.status ===
                                "paid"
                                ? "text-yellow-300"
                                : projectDetails?.status ===
                                  "accepted" ||
                                  projectDetails?.status ===
                                  "done" ||
                                  projectDetails?.status ===
                                  "ongoing"
                                  ? "text-green-300"
                                  : "text-red-300"
                            }`}
                        >
                          {projectDetails?.status ===
                            "pre_payment"
                            ? "پرداخت نشده"
                            : projectDetails?.status ===
                              "paid"
                              ? "در دست بررسی"
                              : projectDetails?.status ===
                                "accepted"
                                ? "منتشر شده"
                                : projectDetails?.status ===
                                  "rejected"
                                  ? "رد شده"
                                  : projectDetails?.status ===
                                    "ongoing"
                                    ? "در دست اقدام"
                                    : projectDetails?.status ===
                                      "done"
                                      ? "انجام شده"
                                      : "کنسل شده"}
                        </p>
                        <>
                          <div className="flex flex-col mr-[100px] items-center py-4">
                            <textarea
                              className="bg-gray-100 rounded-lg p-2 w-[500px] text-black  min-h-[100px]"
                              placeholder="دلیل رد پروژه"
                              name=""
                              id=""
                              onChange={(e) =>
                                setRejectReason(
                                  e.target
                                    .value
                                )
                              }
                              value={rejectReason}
                            ></textarea>
                            <button
                              onClick={() =>
                                rejectProjectAccept(
                                  projectDetails?._id,
                                  projectDetails?.user_id
                                )
                              }
                              className="bg-red-500 mt-5 w-[120px] text-white px-3 py-1 rounded-lg"
                            >
                              رد کردن
                            </button>
                          </div>
                          <button
                            onClick={() =>
                              acceptProjectAccept(
                                projectDetails?._id,
                                projectDetails
                                  ?.creator
                                  ?.user_name
                              )
                            }
                            className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                          >
                            انتشار
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      دلیل رد پروژه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      {projectDetails?.reject_reason}
                    </div>
                  </div>
                  <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                      کاربران پیشنهاد دهنده :
                    </div>
                    <div className="flex items-center font-medium  font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 p-4">
                      <div>
                        <div className="flex-1 w-full flex items-center h-11 px-5">
                          <div className="col-span-1  w-[200px]">
                            <p className="font-KalamehMed text-sm font-medium">
                              نام و نام خانوادگی
                            </p>
                          </div>
                          <div className="col-span-1 w-[150px]">
                            <p className="font-KalamehMed text-sm font-medium">
                              نام کاربری
                            </p>
                          </div>
                          <div className="col-span-1 w-[150px]">
                            <p className="font-KalamehMed text-sm font-medium">
                              قیمت پینهادی
                            </p>
                          </div>
                          <div className="col-span-1  w-[100px]">
                            <p className="font-KalamehMed text-sm font-medium">
                              زمان پیشنهادی
                            </p>
                          </div>
                          <div className="col-span-1  w-[100px]"></div>
                        </div>
                        {projectDetails?.requested_users?.map(
                          (user, index) => (
                            <div className="w-full mt-4 flex items-center bg-[#252525]">
                              <div
                                className={`flex-1 w-full flex items-center h-11 px-5  rounded-lg  py-8 ${index % 2 ==
                                    0
                                    ? "bg-[#c0e2f04b]"
                                    : "bg-[#678fa062]"
                                  }`}
                              >
                                <div className="col-span-1 w-[200px]">
                                  <div className="flex items-center">
                                    <img
                                      className="w-[50px] h-[50px] rounded-md ml-2"
                                      src={
                                        ImageBaseUrl +
                                        user?.profile_image
                                      }
                                      alt=""
                                    />
                                    <p className="font-KalamehMed text-sm font-medium">
                                      {
                                        user?.first_name
                                      }{" "}
                                      {
                                        user?.last_name
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="col-span-1 w-[150px]">
                                  <p className="font-KalamehMed text-sm font-medium">
                                    {
                                      user?.user_name
                                    }
                                  </p>
                                </div>
                                <div className="col-span-1 w-[150px]">
                                  <p className="font-KalamehMed text-sm font-medium">
                                    {Number(
                                      user?.price
                                    ).toLocaleString()}
                                  </p>
                                </div>
                                <div className="col-span-1 w-[150px]">
                                  <p className="font-KalamehMed text-sm font-medium">
                                    {
                                      user?.time
                                    }
                                  </p>
                                </div>

                                <div className="col-span-1 w-[150px] ">
                                  <p className="font-KalamehMed text-sm font-medium">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/users/${user?._id}`
                                        );
                                      }}
                                      className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                                    >
                                      جزئیات
                                    </button>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetail;
